import "./src/select2/js/select2.min.js"
import "./src/select2/css/select2.min.css"
import "./panel.js";
import "./panel.scss";
import "./abstract.scss";
import "./exclusive_popup.scss";
import "./roundtable.js";
import "./override_exclusive_popup.js";
import "./override_user_data_popup.js";
import "./timetable.scss";
import "./outdated_browser.scss";
import "./registration_form.js";
import "./registration_form.scss";
import "./timezone_selector.js"
import "./event_display.scss";
import "./personalised_timetable.js"
import "./personalised_timetable.scss"
import "./overrides.scss"
