// Initialise roundtable table elements on management page.
setupRoundtableList = () => {
  setupTableSorter("#roundtables .tablesorter");

  // Enable menu items (Remove, Judge, ...) only if an elements has been selected from the list.
  enableIfChecked("#roundtables", "input[name=roundtable_id]", ".js-enable-if-checked");

  // Setup search on roundtables table
  var filterConfig = {
    itemHandle: "tr",
    listItems: "#roundtables tbody tr",
    term: "#search-input",
    state: "#filtering-state",
    placeholder: "#filter-placeholder"
  };

  var applySearchFilters = setupListGenerator(filterConfig);
  applySearchFilters();
};
