const urls = ['/event/82/timetable/', '/event/152/timetable/', '/event/153/timetable/', '/event/241/timetable/', '/event/347/timetable/', '/event/431/timetable/'];
if (urls.includes(window.location.pathname)) {
  var pathname_array = window.location.pathname.split('/');
  var event_id = parseInt(pathname_array[2]);
  var intv = setInterval(function() {
    if ($('body').hasClass('timetable-timetable')) {
      clearInterval(intv);
      setTimeout(function() {
        //TODO: Add conditions here
        personalised_timetable(event_id);
      }, 2000);
    }
  }, 500)
}

var personalised_timetable = function(event_id) {

  var even_id = event_id;
  var storage = window.localStorage;
  var personLItems = JSON.parse(storage.getItem(even_id)) || [];

  var $switch_wrapper = $('<ul/>',{'class':'programme-switch no-print'})
  .append(
    $('<li/>',{'class':'full-programme i-button i-button-small','text':'Show full programme'})
    .on('click', function() {
      show_programme('full')
    })
  ).append(
    $('<li/>',{'class':'personal-programme i-button i-button-small highlight','text':'Show personal programme'})
    .on('click', function() {
      show_programme('personal')
    })
  ).append(
    $('<li/>',{'class':'print-personal-programme i-button i-button-small highlight','text':'Print personal programme'})
    .on('click', function() {
      show_programme('personal');
      window.print();
    }).hide()
  )

  $('.event-sub-header').append($switch_wrapper)
  .append('<p class="programme-desc-full no-print">Click on the checkmark icon next to the session title to add items to your personal programme. You can then save or print your personalised programme ready for the conference.</p>')
  .append('<p class="programme-desc-personal no-print">Click on the remove icon next to the session title to remove items from your personal programme.</p>');


  var contribution_list = $('li.timetable-item.timetable-contrib');
  var break_list = $('li.timetable-item.timetable-break');
  console.log(break_list)

  var add_contib = function(contrib_id) {
    personLItems.push(contrib_id);
    storage.setItem(even_id, JSON.stringify(personLItems));
    update_print_btn()
  }

  var remove_contib = function(contrib_id) {
    var index = personLItems.indexOf(contrib_id);
    if (index > -1) {
      personLItems.splice(index, 1);
    }
    storage.setItem(even_id, JSON.stringify(personLItems));
    show_programme('personal');
  }

  var update_print_btn = function() {
    var print_btn = $('.print-personal-programme', $switch_wrapper)
    if (personLItems.length > 0) {
      print_btn.show();
    } else {
      print_btn.hide();
    }
  }

  var show_programme = function(type) {
    var $switch = $('.programme-switch');
    update_print_btn();
    if (type == 'full') {
      break_list.show();
      contribution_list.each(function() {
        var contrib = $(this).show().removeClass('no-print');
        var contrib_id = contrib.attr('id');
        var $title = contrib.find('.timetable-item-header .timetable-title');

        $('.timetatble-btn', $title).remove();

        if (contrib_id && personLItems.indexOf(contrib_id) < 0) {
          $('<a/>', {
            'html': '<i class="icon-checkmark"></i>',
            'class': 'timetatble-btn timetatble-btn-add no-print',
            'title': 'Add to your programme'
          }).css({
            'cursor': 'pointer'
          }).click(function(e) {
            e.preventDefault();
            add_contib(contrib_id);
            $(this).fadeOut();
          }).appendTo($title);
        }
      });
      $('ul.meeting-timetable').each(function() {
        $(this).closest('li').show().removeClass('no-print');
      })
      $('.full-programme',$switch).removeClass('highlight');
      $('.programme-desc-full','.event-sub-header').show();
      $('.programme-desc-personal','.event-sub-header').hide();
      $('.personal-programme',$switch).addClass('highlight');
    } else {
      break_list.hide();
      contribution_list.each(function() {
        var contrib = $(this);
        var contrib_id = contrib.attr('id');
        if (personLItems.indexOf(contrib_id) > -1) {

          $(this).show();

          var $title = contrib.find('.timetable-item-header .timetable-title');
          $('.timetatble-btn', $title).remove();

          $('<a/>', {
              'html': '<i class="icon-remove"></i>',
              'class': 'timetatble-btn timetatble-btn-remove no-print',
              'title': 'Remove from your programme'
            }).css({
              'cursor': 'pointer'
            }).click(function(e) {
              e.preventDefault();
              remove_contib(contrib_id);
              contrib.fadeOut();
            }).appendTo($title);

        } else {
          $(this).hide().addClass('no-print');
        }
      });

      $('ul.meeting-timetable').each(function() {
        var is_empty = true;
        $(this).find('li.timetable-item.timetable-contrib').each(function() {
          if ($(this).is(':visible')) {
            is_empty = false;
            return false;
          }
        });
        if (is_empty) {
          $(this).closest('li').hide().addClass('no-print');
        }

      });


      $('.full-programme',$switch).addClass('highlight');
      $('.programme-desc-full','.event-sub-header').hide();
      $('.personal-programme',$switch).removeClass('highlight');
      $('.programme-desc-personal','.event-sub-header').show();
    }
  }

  show_programme('full');
}
