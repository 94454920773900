setupPanelPerson = (field_id, single) => {
  // Delay execution until all setupPersonLinkWidget() functions run
  if (single) {
    setTimeout(allowOnlyOnePerson(field_id), 100);
  }
  
};

// Hide the footer bar for person form fields that contain the class *single* and
// have a child element. This limits the addition to only 1 person.
const allowOnlyOnePerson = (field_id) => {
  const elem = $('#' + field_id).on('change', function() {
    let val = $(this).val();
    const buttons = $(this).closest('.form-field').find('.ui.bottom.buttons');
    if (val == '[]') {
      buttons.show();
    } else {
      buttons.hide();
    }
  }).trigger('change')
  
  // Callback function to execute when mutations are observed on child DOM elements
  const callback = mutations => {
    let target = mutations[0].target;
    let commonParent = target.parentElement.parentElement;
    let footer = commonParent.querySelector(".i-box-footer");
    if (target.children.length != 0) {
      footer.style = "display:none;";
    } else {
      footer.style = "";
    }
  };
};

// Initialise panel table elements on management page.
setupPanelList = () => {
  setupTableSorter("#panels .tablesorter");

  // Enable menu items (Remove, Judge, ...) only if an elements has been selected from the list.
  enableIfChecked("#panels", "input[name=panel_id]", ".js-enable-if-checked");

  // Setup search on panels table
  var filterConfig = {
    itemHandle: "tr",
    listItems: "#panels tbody tr",
    term: "#search-input",
    state: "#filtering-state",
    placeholder: "#filter-placeholder"
  };

  var applySearchFilters = setupListGenerator(filterConfig);
  applySearchFilters();
};
