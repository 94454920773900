import linkifyElement from "linkifyjs/element";

// Turns text into links inside the registration form.
// Allows for the Code of conduct link to be clickabel.
$(document).ready(function() {
  function linkifyForm() {
    document.querySelectorAll(".regform-section").forEach(el => linkifyElement(el));
  }
  // The form is rendered on the frontend and we need to wait a bit before the element exist.
  // 5 seconds looks like a safe margin.
  setTimeout(linkifyForm, 5000);
});
